import * as React from "react";

import { Autocomplete, TextField, styled, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import {
  getAssignedEmployees,
  getEmployee,
} from "../../redux/actions/employeeActions";
import { getFloors } from "../../redux/actions/floorActions";
import { getBuildings } from "../../redux/actions/buildingActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { AppRoutes } from "../../config";
import { handleErrors } from "utils/helpers";
import { EmployeeTypeEnum } from "../../enums/EmployeeTypeEnum";

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 1rem;
    margin: 5;
  }
`;

type User = {
  id: number;
  name: string;
  username: string;
};
type Floor = {
  id: number;
  name: string;
};
type Building = {
  id: number;
  name: string;
};

interface WorkspaceNavProps {
  title?: string;
}

export const WorkspaceNav: React.FC<WorkspaceNavProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const {assignedEmployees, assignedEmployeesLoadingState} = useAppSelector((state) => state.employee);
  const {buildingsLoadingState, buildings: buildingValues} = useAppSelector(state => state.building);
  const {floorsLoadingState, floors} = useAppSelector(state => state.floor);
  const navigate = useNavigate();
  const { buildingId, floorId, workstationId } = useParams();
  const [employeeValue, setEmployeeValue] = React.useState<User | null>(null);
  const [floorValue, setFloorValue] = React.useState<Floor | null>(null);
  const [buildingValue, setBuildingValue] = React.useState<Building | null>(
    null
  );

  const floorValues = React.useMemo(() => floors.map(item => ({name: item.nameForAdmins!, id: item.id!})), [floors]);
  const employeeValues = React.useMemo(
    () => assignedEmployees.reduce((acc, curr) => {
      if (curr.status === EmployeeTypeEnum.Office && !curr.workstation) {
       return acc;
      }

      return [...acc,  {
        name: curr.name!,
        id: curr.id!,
        username: curr.username!,
      }]
     }, [] as User[])
    , [assignedEmployees]);

  const handleEmployeeChange = (id: number) => {
    setBuildingValue(null);
    setFloorValue(null);
    if (id !== undefined) {
      dispatch(getEmployee(id))
        .unwrap()
        .then((res) => {
          if (res.status === EmployeeTypeEnum.Office && res.workstation) {
            navigate(
              AppRoutes.createRouteForWorkstation(
                res.workstation.room?.floor?.id!,
                res.workstation.room?.id!,
                res.workstation.id!
              )
            );
            return;
          }

          if (res.status === EmployeeTypeEnum.Home) {
            navigate(AppRoutes.createRouteForEmployeeFind("home", res.id!));
            return;
          }

          if (res.status === EmployeeTypeEnum.Inactive) {
            navigate(AppRoutes.createRouteForEmployeeFind("inactive", res.id!));
            return;
          }
        })
        .catch((res) => {
          handleErrors(res);
        });
    }
  };

  const handleFloorChange = (id: number) => {
    setEmployeeValue(null);
    setBuildingValue(null);
    if (id !== undefined) {
      navigate(AppRoutes.createRouteForFloor(id));
    }
  };

  const handleBuildingChange = (id: number) => {
    setEmployeeValue(null);
    setFloorValue(null);
    if (id !== undefined) {
      navigate(AppRoutes.createRouteForBuilding(id));
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleBackButtonView = () => {
    if (
      buildingId !== undefined ||
      floorId !== undefined ||
      workstationId !== undefined
    ) {
      return (
        <li className="panel-head__item panel-head__item--back">
          <button type="button" className="btn-back" onClick={handleBack}>
            Back
          </button>
        </li>
      );
    }

    return null;
  };

  const handleSearchView = () => {
    return (
      <>
        <li className="panel-head__item">
          <div className="panel-head__search">
            <AutoComplete
              onChange={(_, value: unknown) => {
                setEmployeeValue(value as User);
                handleEmployeeChange((value as User)?.id);
              }}
              id="controllable-states-demo"
              autoHighlight
              options={[...employeeValues]}
              value={employeeValue}
              getOptionLabel={(option) => ` ${(option as User).name}`}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={(option as User).id}>
                  {(option as User).name} ({(option as User).username})
                </Box>
              )}
              sx={{ width: 260, background: "white" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Find colleague"
                  placeholder={
                    assignedEmployeesLoadingState === 'Loading' ? "Loading, please wait..." : "Search..."
                  }
                />
              )}
            />
          </div>
        </li>
        <li className="panel-head__item">
          <div className="panel-head__search">
            <AutoComplete
              clearOnBlur={true}
              onChange={(_, value: unknown) => {
                setFloorValue(value as Floor);
                handleFloorChange((value as Floor)?.id);
              }}
              id="controllable-states-demo"
              autoHighlight
              options={[...floorValues]}
              value={floorValue}
              getOptionLabel={(option) => `${(option as Floor).name}`}
              renderOption={(props, option) => (
                <Box key={(option as Floor).id} component="li" {...props}>
                  {(option as Floor).name}
                </Box>
              )}
              sx={{ width: 260, background: "white" }}
              renderInput={(params) => (
                <TextField {...params} label="Find floor" />
              )}
            />
          </div>
        </li>
        <li className="panel-head__item">
          <div className="panel-head__search">
            <AutoComplete
              onChange={(_, value: unknown) => {
                setBuildingValue(value as Building);
                handleBuildingChange((value as Building)?.id);
              }}
              id="controllable-states-demo"
              autoHighlight
              options={[...buildingValues]}
              value={buildingValue}
              getOptionLabel={(option) => `${(option as Building).name}`}
              renderOption={(props, option) => (
                <Box key={(option as Building).id} component="li" {...props}>
                  {(option as Building).name}
                </Box>
              )}
              sx={{ width: 260, background: "white" }}
              renderInput={(params) => (
                <TextField {...params} label="Find building" />
              )}
            />
          </div>
        </li>
      </>
    );
  };

  React.useEffect(() => {
    if (assignedEmployeesLoadingState === 'Loaded' || assignedEmployeesLoadingState === 'Loading') return;

    dispatch(getAssignedEmployees());
  }, [dispatch, assignedEmployeesLoadingState]);

  React.useEffect(() => {
    if (floorsLoadingState === 'Loaded' || floorsLoadingState === 'Loading') return;

    dispatch(getFloors());
  }, [dispatch, floorsLoadingState]);

  React.useEffect(() => {
    if (buildingsLoadingState === 'Loaded' || buildingsLoadingState === 'Loading') return;
    
    dispatch(getBuildings());
  }, [buildingsLoadingState, dispatch]);

  return (
    <div className="panel-head panel-head--page">
      {title && <h3 className="cmp-title">{title}</h3>}
      <ul className="panel-head__list">
        {handleBackButtonView()}
        {handleSearchView()}
      </ul>
    </div>
  );
};
