/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
type RawAxiosRequestConfig = AxiosRequestConfig;
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessControlTokenRequest
 */
export interface AccessControlTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessControlTokenRequest
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlTokenRequest
     */
    'cardNo'?: string;
}
/**
 * 
 * @export
 * @interface AccessControlTokenResponse
 */
export interface AccessControlTokenResponse {
    /**
     * 
     * @type {number}
     * @memberof AccessControlTokenResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessControlTokenResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlTokenResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlTokenResponse
     */
    'cardNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlTokenResponse
     */
    'regDate'?: string;
}
/**
 * 
 * @export
 * @interface AccessControlTokenUpdateRequest
 */
export interface AccessControlTokenUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessControlTokenUpdateRequest
     */
    'fullName'?: string;
}
/**
 * 
 * @export
 * @interface AccessControlWeekDayEventReportResponse
 */
export interface AccessControlWeekDayEventReportResponse {
    /**
     * 
     * @type {string}
     * @memberof AccessControlWeekDayEventReportResponse
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlWeekDayEventReportResponse
     */
    'dateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlWeekDayEventReportResponse
     */
    'location'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminRequest
 */
export interface AdminRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface AdminResponse
 */
export interface AdminResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminResponse
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface BuildingRequest
 */
export interface BuildingRequest {
    /**
     * 
     * @type {string}
     * @memberof BuildingRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingRequest
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingRequest
     */
    'cityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingRequest
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingRequest
     */
    'latitude'?: number;
}
/**
 * 
 * @export
 * @interface BuildingResponse
 */
export interface BuildingResponse {
    /**
     * 
     * @type {number}
     * @memberof BuildingResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingResponse
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingResponse
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingResponse
     */
    'latitude'?: number;
    /**
     * 
     * @type {CityResponse}
     * @memberof BuildingResponse
     */
    'city'?: CityResponse;
}
/**
 * 
 * @export
 * @interface BuildingStatistic
 */
export interface BuildingStatistic {
    /**
     * 
     * @type {number}
     * @memberof BuildingStatistic
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingStatistic
     */
    'name'?: string;
    /**
     * 
     * @type {Array<BuildingStatisticBuilding>}
     * @memberof BuildingStatistic
     */
    'buildingStatisticBuildings'?: Array<BuildingStatisticBuilding>;
}
/**
 * 
 * @export
 * @interface BuildingStatisticBuilding
 */
export interface BuildingStatisticBuilding {
    /**
     * 
     * @type {number}
     * @memberof BuildingStatisticBuilding
     */
    'id'?: number;
    /**
     * 
     * @type {Array<BuildingStatisticFloor>}
     * @memberof BuildingStatisticBuilding
     */
    'buildingStatisticFloors'?: Array<BuildingStatisticFloor>;
    /**
     * 
     * @type {string}
     * @memberof BuildingStatisticBuilding
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface BuildingStatisticFloor
 */
export interface BuildingStatisticFloor {
    /**
     * 
     * @type {number}
     * @memberof BuildingStatisticFloor
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingStatisticFloor
     */
    'name'?: string;
    /**
     * 
     * @type {Array<BuildingStatisticRoom>}
     * @memberof BuildingStatisticFloor
     */
    'buildingStatisticOffices'?: Array<BuildingStatisticRoom>;
    /**
     * 
     * @type {Array<BuildingStatisticRoom>}
     * @memberof BuildingStatisticFloor
     */
    'buildingStatisticConfs'?: Array<BuildingStatisticRoom>;
}
/**
 * 
 * @export
 * @interface BuildingStatisticRoom
 */
export interface BuildingStatisticRoom {
    /**
     * 
     * @type {number}
     * @memberof BuildingStatisticRoom
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingStatisticRoom
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingStatisticRoom
     */
    'totalTables'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingStatisticRoom
     */
    'availableTables'?: number;
}
/**
 * 
 * @export
 * @interface BuildingUpdateRequest
 */
export interface BuildingUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof BuildingUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingUpdateRequest
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingUpdateRequest
     */
    'cityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingUpdateRequest
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingUpdateRequest
     */
    'latitude'?: number;
    /**
     * 
     * @type {Array<FloorUpdateInBuildingRequest>}
     * @memberof BuildingUpdateRequest
     */
    'floorList'?: Array<FloorUpdateInBuildingRequest>;
}
/**
 * 
 * @export
 * @interface BuildingWithFloorInfoResponse
 */
export interface BuildingWithFloorInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof BuildingWithFloorInfoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingWithFloorInfoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingWithFloorInfoResponse
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingWithFloorInfoResponse
     */
    'longitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingWithFloorInfoResponse
     */
    'latitude'?: number;
    /**
     * 
     * @type {CityResponse}
     * @memberof BuildingWithFloorInfoResponse
     */
    'city'?: CityResponse;
    /**
     * 
     * @type {Array<FloorResponse>}
     * @memberof BuildingWithFloorInfoResponse
     */
    'floorList'?: Array<FloorResponse>;
}
/**
 * 
 * @export
 * @interface CityRequest
 */
export interface CityRequest {
    /**
     * 
     * @type {string}
     * @memberof CityRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CityRequest
     */
    'countryId'?: number;
}
/**
 * 
 * @export
 * @interface CityResponse
 */
export interface CityResponse {
    /**
     * 
     * @type {number}
     * @memberof CityResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CityResponse
     */
    'name'?: string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof CityResponse
     */
    'country'?: CountryResponse;
}
/**
 * 
 * @export
 * @interface CompanyRequest
 */
export interface CompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CompanyStatistic
 */
export interface CompanyStatistic {
    /**
     * 
     * @type {string}
     * @memberof CompanyStatistic
     */
    'company'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyStatistic
     */
    'totalEmployee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStatistic
     */
    'office'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStatistic
     */
    'home'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStatistic
     */
    'inactive'?: number;
    /**
     * 
     * @type {Array<CompanyStatisticBuilding>}
     * @memberof CompanyStatistic
     */
    'companyStatisticBuildings'?: Array<CompanyStatisticBuilding>;
}
/**
 * 
 * @export
 * @interface CompanyStatisticBuilding
 */
export interface CompanyStatisticBuilding {
    /**
     * 
     * @type {string}
     * @memberof CompanyStatisticBuilding
     */
    'buildingName'?: string;
    /**
     * 
     * @type {Array<CompanyStatisticFloor>}
     * @memberof CompanyStatisticBuilding
     */
    'floors'?: Array<CompanyStatisticFloor>;
}
/**
 * 
 * @export
 * @interface CompanyStatisticFloor
 */
export interface CompanyStatisticFloor {
    /**
     * 
     * @type {string}
     * @memberof CompanyStatisticFloor
     */
    'name'?: string;
    /**
     * 
     * @type {Array<CompanyStatisticRoom>}
     * @memberof CompanyStatisticFloor
     */
    'offices'?: Array<CompanyStatisticRoom>;
}
/**
 * 
 * @export
 * @interface CompanyStatisticRoom
 */
export interface CompanyStatisticRoom {
    /**
     * 
     * @type {string}
     * @memberof CompanyStatisticRoom
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyStatisticRoom
     */
    'totalEmployees'?: number;
}
/**
 * 
 * @export
 * @interface ConferenceRoomRequest
 */
export interface ConferenceRoomRequest {
    /**
     * 
     * @type {number}
     * @memberof ConferenceRoomRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConferenceRoomRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConferenceRoomRequest
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConferenceRoomRequest
     */
    'type'?: ConferenceRoomRequestTypeEnum;
}

export const ConferenceRoomRequestTypeEnum = {
    Office: 'OFFICE',
    Conference: 'CONFERENCE'
} as const;

export type ConferenceRoomRequestTypeEnum = typeof ConferenceRoomRequestTypeEnum[keyof typeof ConferenceRoomRequestTypeEnum];

/**
 * 
 * @export
 * @interface CountryRequest
 */
export interface CountryRequest {
    /**
     * 
     * @type {string}
     * @memberof CountryRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CountryResponse
 */
export interface CountryResponse {
    /**
     * 
     * @type {number}
     * @memberof CountryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeRequest
 */
export interface EmployeeRequest {
    /**
     * 
     * @type {number}
     * @memberof EmployeeRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'additionalNote'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'reasonForDeactivation'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'workstation'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeRequest
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRequest
     */
    'accessControlNumber'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeResponse
 */
export interface EmployeeResponse {
    /**
     * 
     * @type {number}
     * @memberof EmployeeResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'additionalNote'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'reasonForDeactivation'?: string;
    /**
     * 
     * @type {CompanyResponse}
     * @memberof EmployeeResponse
     */
    'company'?: CompanyResponse;
    /**
     * 
     * @type {WorkstationResponse}
     * @memberof EmployeeResponse
     */
    'workstation'?: WorkstationResponse;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'role'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    'accessControlNumber'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeResponseLite
 */
export interface EmployeeResponseLite {
    /**
     * 
     * @type {number}
     * @memberof EmployeeResponseLite
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseLite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseLite
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponseLite
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeStillInsideResponse
 */
export interface EmployeeStillInsideResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeStillInsideResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStillInsideResponse
     */
    'cardNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeStillInsideResponse
     */
    'location'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeUpdateRequest
 */
export interface EmployeeUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'additionalNote'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'reasonForDeactivation'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'workstation'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeUpdateRequest
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'accessControlNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdateRequest
     */
    'role'?: EmployeeUpdateRequestRoleEnum;
}

export const EmployeeUpdateRequestRoleEnum = {
    Admin: 'ADMIN',
    SuperAdmin: 'SUPER_ADMIN',
    User: 'USER'
} as const;

export type EmployeeUpdateRequestRoleEnum = typeof EmployeeUpdateRequestRoleEnum[keyof typeof EmployeeUpdateRequestRoleEnum];

/**
 * 
 * @export
 * @interface FloorResponse
 */
export interface FloorResponse {
    /**
     * 
     * @type {number}
     * @memberof FloorResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorResponse
     */
    'nameForAdmins'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorResponse
     */
    'nameForUsers'?: string;
    /**
     * 
     * @type {number}
     * @memberof FloorResponse
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorResponse
     */
    'svgUrl'?: string;
}
/**
 * 
 * @export
 * @interface FloorUpdateInBuildingRequest
 */
export interface FloorUpdateInBuildingRequest {
    /**
     * 
     * @type {number}
     * @memberof FloorUpdateInBuildingRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorUpdateInBuildingRequest
     */
    'nameForAdmins'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorUpdateInBuildingRequest
     */
    'nameForUsers'?: string;
    /**
     * 
     * @type {number}
     * @memberof FloorUpdateInBuildingRequest
     */
    'level'?: number;
}
/**
 * 
 * @export
 * @interface FloorWithBuildingInfoResponse
 */
export interface FloorWithBuildingInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof FloorWithBuildingInfoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorWithBuildingInfoResponse
     */
    'nameForAdmins'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorWithBuildingInfoResponse
     */
    'nameForUsers'?: string;
    /**
     * 
     * @type {number}
     * @memberof FloorWithBuildingInfoResponse
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorWithBuildingInfoResponse
     */
    'svgUrl'?: string;
    /**
     * 
     * @type {BuildingResponse}
     * @memberof FloorWithBuildingInfoResponse
     */
    'building'?: BuildingResponse;
}
/**
 * 
 * @export
 * @interface GeneralStatistic
 */
export interface GeneralStatistic {
    /**
     * 
     * @type {string}
     * @memberof GeneralStatistic
     */
    'company'?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatistic
     */
    'totalEmployee'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatistic
     */
    'office'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatistic
     */
    'home'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatistic
     */
    'inactive'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatistic
     */
    'totalWorkstation'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatistic
     */
    'available'?: number;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface OfficeRoomRequest
 */
export interface OfficeRoomRequest {
    /**
     * 
     * @type {number}
     * @memberof OfficeRoomRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficeRoomRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficeRoomRequest
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficeRoomRequest
     */
    'type'?: OfficeRoomRequestTypeEnum;
}

export const OfficeRoomRequestTypeEnum = {
    Office: 'OFFICE',
    Conference: 'CONFERENCE'
} as const;

export type OfficeRoomRequestTypeEnum = typeof OfficeRoomRequestTypeEnum[keyof typeof OfficeRoomRequestTypeEnum];

/**
 * 
 * @export
 * @interface RoomResponse
 */
export interface RoomResponse {
    /**
     * 
     * @type {number}
     * @memberof RoomResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomResponse
     */
    'name'?: string;
    /**
     * 
     * @type {FloorWithBuildingInfoResponse}
     * @memberof RoomResponse
     */
    'floor'?: FloorWithBuildingInfoResponse;
    /**
     * 
     * @type {string}
     * @memberof RoomResponse
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface SetPasswordRequest
 */
export interface SetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof SetPasswordRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPasswordRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface TimeInSpaceBasicReportResponse
 */
export interface TimeInSpaceBasicReportResponse {
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceBasicReportResponse
     */
    'employeeFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceBasicReportResponse
     */
    'employeeCardNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceBasicReportResponse
     */
    'timeInSpace'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceBasicReportResponse
     */
    'timeInSpaceToday'?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeInSpaceBasicReportResponse
     */
    'employeeId'?: number;
}
/**
 * 
 * @export
 * @interface TimeInSpaceWeekDayReportResponse
 */
export interface TimeInSpaceWeekDayReportResponse {
    /**
     * 
     * @type {Array<AccessControlWeekDayEventReportResponse>}
     * @memberof TimeInSpaceWeekDayReportResponse
     */
    'events'?: Array<AccessControlWeekDayEventReportResponse>;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceWeekDayReportResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceWeekDayReportResponse
     */
    'timeInSpace'?: string;
}
/**
 * 
 * @export
 * @interface TimeInSpaceWeekReportResponse
 */
export interface TimeInSpaceWeekReportResponse {
    /**
     * 
     * @type {Array<TimeInSpaceWeekDayReportResponse>}
     * @memberof TimeInSpaceWeekReportResponse
     */
    'days'?: Array<TimeInSpaceWeekDayReportResponse>;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceWeekReportResponse
     */
    'employeeFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceWeekReportResponse
     */
    'employeeCardNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeInSpaceWeekReportResponse
     */
    'timeInSpace'?: string;
}
/**
 * 
 * @export
 * @interface TokenInfoResponse
 */
export interface TokenInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenInfoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenInfoResponse
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenInfoResponse
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenInfoResponse
     */
    'timeInSpace'?: string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface WorkspaceUpdatesResponse
 */
export interface WorkspaceUpdatesResponse {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUpdatesResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdatesResponse
     */
    'employeeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdatesResponse
     */
    'employeeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdatesResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdatesResponse
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdatesResponse
     */
    'action'?: WorkspaceUpdatesResponseActionEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdatesResponse
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdatesResponse
     */
    'actionPerformedByEmployeeName'?: string;
}

export const WorkspaceUpdatesResponseActionEnum = {
    Detach: 'DETACH',
    Attach: 'ATTACH'
} as const;

export type WorkspaceUpdatesResponseActionEnum = typeof WorkspaceUpdatesResponseActionEnum[keyof typeof WorkspaceUpdatesResponseActionEnum];

/**
 * 
 * @export
 * @interface WorkstationRequest
 */
export interface WorkstationRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkstationRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkstationRequest
     */
    'roomId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkstationRequest
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface WorkstationResponse
 */
export interface WorkstationResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkstationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {RoomResponse}
     * @memberof WorkstationResponse
     */
    'room'?: RoomResponse;
    /**
     * 
     * @type {string}
     * @memberof WorkstationResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkstationResponse
     */
    'company'?: string;
}

/**
 * AccessControlControllerApi - axios parameter creator
 * @export
 */
export const AccessControlControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccessControlTokenRequest} accessControlTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCard: async (accessControlTokenRequest: AccessControlTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessControlTokenRequest' is not null or undefined
            assertParamExists('createCard', 'accessControlTokenRequest', accessControlTokenRequest)
            const localVarPath = `/api/access-control/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessControlTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCard', 'id', id)
            const localVarPath = `/api/access-control/cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCards: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/access-control/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCard', 'id', id)
            const localVarPath = `/api/access-control/cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AccessControlTokenUpdateRequest} accessControlTokenUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCard: async (id: number, accessControlTokenUpdateRequest: AccessControlTokenUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCard', 'id', id)
            // verify required parameter 'accessControlTokenUpdateRequest' is not null or undefined
            assertParamExists('updateCard', 'accessControlTokenUpdateRequest', accessControlTokenUpdateRequest)
            const localVarPath = `/api/access-control/cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessControlTokenUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessControlControllerApi - functional programming interface
 * @export
 */
export const AccessControlControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessControlControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccessControlTokenRequest} accessControlTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCard(accessControlTokenRequest: AccessControlTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessControlTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCard(accessControlTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessControlControllerApi.createCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCard(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCard(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessControlControllerApi.deleteCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCards(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessControlTokenResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCards(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessControlControllerApi.getAllCards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCard(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessControlTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCard(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessControlControllerApi.getCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {AccessControlTokenUpdateRequest} accessControlTokenUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCard(id: number, accessControlTokenUpdateRequest: AccessControlTokenUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessControlTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCard(id, accessControlTokenUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccessControlControllerApi.updateCard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccessControlControllerApi - factory interface
 * @export
 */
export const AccessControlControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessControlControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessControlTokenRequest} accessControlTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCard(accessControlTokenRequest: AccessControlTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessControlTokenResponse> {
            return localVarFp.createCard(accessControlTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCards(options?: RawAxiosRequestConfig): AxiosPromise<Array<AccessControlTokenResponse>> {
            return localVarFp.getAllCards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AccessControlTokenResponse> {
            return localVarFp.getCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AccessControlTokenUpdateRequest} accessControlTokenUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCard(id: number, accessControlTokenUpdateRequest: AccessControlTokenUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessControlTokenResponse> {
            return localVarFp.updateCard(id, accessControlTokenUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessControlControllerApi - object-oriented interface
 * @export
 * @class AccessControlControllerApi
 * @extends {BaseAPI}
 */
export class AccessControlControllerApi extends BaseAPI {
    /**
     * 
     * @param {AccessControlTokenRequest} accessControlTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlControllerApi
     */
    public createCard(accessControlTokenRequest: AccessControlTokenRequest, options?: RawAxiosRequestConfig) {
        return AccessControlControllerApiFp(this.configuration).createCard(accessControlTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlControllerApi
     */
    public deleteCard(id: number, options?: RawAxiosRequestConfig) {
        return AccessControlControllerApiFp(this.configuration).deleteCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlControllerApi
     */
    public getAllCards(options?: RawAxiosRequestConfig) {
        return AccessControlControllerApiFp(this.configuration).getAllCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlControllerApi
     */
    public getCard(id: number, options?: RawAxiosRequestConfig) {
        return AccessControlControllerApiFp(this.configuration).getCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AccessControlTokenUpdateRequest} accessControlTokenUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlControllerApi
     */
    public updateCard(id: number, accessControlTokenUpdateRequest: AccessControlTokenUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccessControlControllerApiFp(this.configuration).updateCard(id, accessControlTokenUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmin: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdmin', 'id', id)
            const localVarPath = `/api/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminById', 'id', id)
            const localVarPath = `/api/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdmins: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminRequest} adminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdmin: async (adminRequest: AdminRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminRequest' is not null or undefined
            assertParamExists('saveAdmin', 'adminRequest', adminRequest)
            const localVarPath = `/api/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminRequest} adminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmin: async (id: number, adminRequest: AdminRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdmin', 'id', id)
            // verify required parameter 'adminRequest' is not null or undefined
            assertParamExists('updateAdmin', 'adminRequest', adminRequest)
            const localVarPath = `/api/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdmin(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdmin(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminControllerApi.deleteAdmin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminControllerApi.getAdminById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdmins(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAdmins(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminControllerApi.getAllAdmins']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminRequest} adminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAdmin(adminRequest: AdminRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAdmin(adminRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminControllerApi.saveAdmin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminRequest} adminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdmin(id: number, adminRequest: AdminRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdmin(id, adminRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminControllerApi.updateAdmin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmin(id: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.deleteAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminResponse> {
            return localVarFp.getAdminById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdmins(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminResponse>> {
            return localVarFp.getAllAdmins(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRequest} adminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdmin(adminRequest: AdminRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminResponse> {
            return localVarFp.saveAdmin(adminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminRequest} adminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmin(id: number, adminRequest: AdminRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminResponse> {
            return localVarFp.updateAdmin(id, adminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteAdmin(id: number, options?: RawAxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).deleteAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAdminById(id: number, options?: RawAxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getAdminById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAllAdmins(options?: RawAxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getAllAdmins(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRequest} adminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public saveAdmin(adminRequest: AdminRequest, options?: RawAxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).saveAdmin(adminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AdminRequest} adminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateAdmin(id: number, adminRequest: AdminRequest, options?: RawAxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateAdmin(id, adminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('changePassword', 'username', username)
            const localVarPath = `/auth/forget-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePassword: async (setPasswordRequest: SetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasswordRequest' is not null or undefined
            assertParamExists('savePassword', 'setPasswordRequest', setPasswordRequest)
            const localVarPath = `/auth/set-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationControllerApi - functional programming interface
 * @export
 */
export const AuthenticationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.changePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePassword(setPasswordRequest: SetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePassword(setPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.savePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationControllerApi - factory interface
 * @export
 */
export const AuthenticationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(username: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.changePassword(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPasswordRequest} setPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePassword(setPasswordRequest: SetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.savePassword(setPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationControllerApi - object-oriented interface
 * @export
 * @class AuthenticationControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public changePassword(username: string, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).changePassword(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPasswordRequest} setPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public savePassword(setPasswordRequest: SetPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).savePassword(setPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BuildingControllerApi - axios parameter creator
 * @export
 */
export const BuildingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBuilding', 'id', id)
            const localVarPath = `/api/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBuildings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBuildingById', 'id', id)
            const localVarPath = `/api/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingRequest} buildingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBuilding: async (buildingRequest: BuildingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingRequest' is not null or undefined
            assertParamExists('saveBuilding', 'buildingRequest', buildingRequest)
            const localVarPath = `/api/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BuildingUpdateRequest} buildingUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding: async (id: number, buildingUpdateRequest: BuildingUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBuilding', 'id', id)
            // verify required parameter 'buildingUpdateRequest' is not null or undefined
            assertParamExists('updateBuilding', 'buildingUpdateRequest', buildingUpdateRequest)
            const localVarPath = `/api/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingControllerApi - functional programming interface
 * @export
 */
export const BuildingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBuilding(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingWithFloorInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBuilding(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuildingControllerApi.deleteBuilding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBuildings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingWithFloorInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBuildings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuildingControllerApi.getAllBuildings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuildingById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingWithFloorInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuildingById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuildingControllerApi.getBuildingById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BuildingRequest} buildingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBuilding(buildingRequest: BuildingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingWithFloorInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBuilding(buildingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuildingControllerApi.saveBuilding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {BuildingUpdateRequest} buildingUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBuilding(id: number, buildingUpdateRequest: BuildingUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingWithFloorInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBuilding(id, buildingUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuildingControllerApi.updateBuilding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BuildingControllerApi - factory interface
 * @export
 */
export const BuildingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options?: RawAxiosRequestConfig): AxiosPromise<BuildingWithFloorInfoResponse> {
            return localVarFp.deleteBuilding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBuildings(options?: RawAxiosRequestConfig): AxiosPromise<Array<BuildingWithFloorInfoResponse>> {
            return localVarFp.getAllBuildings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<BuildingWithFloorInfoResponse> {
            return localVarFp.getBuildingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BuildingRequest} buildingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBuilding(buildingRequest: BuildingRequest, options?: RawAxiosRequestConfig): AxiosPromise<BuildingWithFloorInfoResponse> {
            return localVarFp.saveBuilding(buildingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BuildingUpdateRequest} buildingUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(id: number, buildingUpdateRequest: BuildingUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<BuildingWithFloorInfoResponse> {
            return localVarFp.updateBuilding(id, buildingUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingControllerApi - object-oriented interface
 * @export
 * @class BuildingControllerApi
 * @extends {BaseAPI}
 */
export class BuildingControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingControllerApi
     */
    public deleteBuilding(id: number, options?: RawAxiosRequestConfig) {
        return BuildingControllerApiFp(this.configuration).deleteBuilding(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingControllerApi
     */
    public getAllBuildings(options?: RawAxiosRequestConfig) {
        return BuildingControllerApiFp(this.configuration).getAllBuildings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingControllerApi
     */
    public getBuildingById(id: number, options?: RawAxiosRequestConfig) {
        return BuildingControllerApiFp(this.configuration).getBuildingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuildingRequest} buildingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingControllerApi
     */
    public saveBuilding(buildingRequest: BuildingRequest, options?: RawAxiosRequestConfig) {
        return BuildingControllerApiFp(this.configuration).saveBuilding(buildingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BuildingUpdateRequest} buildingUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingControllerApi
     */
    public updateBuilding(id: number, buildingUpdateRequest: BuildingUpdateRequest, options?: RawAxiosRequestConfig) {
        return BuildingControllerApiFp(this.configuration).updateBuilding(id, buildingUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CityControllerApi - axios parameter creator
 * @export
 */
export const CityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCity: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCity', 'id', id)
            const localVarPath = `/api/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCities: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCityById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCityById', 'id', id)
            const localVarPath = `/api/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CityRequest} cityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCity: async (cityRequest: CityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityRequest' is not null or undefined
            assertParamExists('saveCity', 'cityRequest', cityRequest)
            const localVarPath = `/api/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CityRequest} cityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCity: async (id: number, cityRequest: CityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCity', 'id', id)
            // verify required parameter 'cityRequest' is not null or undefined
            assertParamExists('updateCity', 'cityRequest', cityRequest)
            const localVarPath = `/api/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CityControllerApi - functional programming interface
 * @export
 */
export const CityControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CityControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCity(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CityControllerApi.deleteCity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCities(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCities(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CityControllerApi.getAllCities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCityById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCityById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CityControllerApi.getCityById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CityRequest} cityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCity(cityRequest: CityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCity(cityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CityControllerApi.saveCity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {CityRequest} cityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCity(id: number, cityRequest: CityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCity(id, cityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CityControllerApi.updateCity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CityControllerApi - factory interface
 * @export
 */
export const CityControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CityControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCity(id: number, options?: RawAxiosRequestConfig): AxiosPromise<CityResponse> {
            return localVarFp.deleteCity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCities(options?: RawAxiosRequestConfig): AxiosPromise<Array<CityResponse>> {
            return localVarFp.getAllCities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCityById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<CityResponse> {
            return localVarFp.getCityById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CityRequest} cityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCity(cityRequest: CityRequest, options?: RawAxiosRequestConfig): AxiosPromise<CityResponse> {
            return localVarFp.saveCity(cityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CityRequest} cityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCity(id: number, cityRequest: CityRequest, options?: RawAxiosRequestConfig): AxiosPromise<CityResponse> {
            return localVarFp.updateCity(id, cityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CityControllerApi - object-oriented interface
 * @export
 * @class CityControllerApi
 * @extends {BaseAPI}
 */
export class CityControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityControllerApi
     */
    public deleteCity(id: number, options?: RawAxiosRequestConfig) {
        return CityControllerApiFp(this.configuration).deleteCity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityControllerApi
     */
    public getAllCities(options?: RawAxiosRequestConfig) {
        return CityControllerApiFp(this.configuration).getAllCities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityControllerApi
     */
    public getCityById(id: number, options?: RawAxiosRequestConfig) {
        return CityControllerApiFp(this.configuration).getCityById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityRequest} cityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityControllerApi
     */
    public saveCity(cityRequest: CityRequest, options?: RawAxiosRequestConfig) {
        return CityControllerApiFp(this.configuration).saveCity(cityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CityRequest} cityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityControllerApi
     */
    public updateCity(id: number, cityRequest: CityRequest, options?: RawAxiosRequestConfig) {
        return CityControllerApiFp(this.configuration).updateCity(id, cityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyControllerApi - axios parameter creator
 * @export
 */
export const CompanyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompany', 'id', id)
            const localVarPath = `/api/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyById', 'id', id)
            const localVarPath = `/api/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyRequest} companyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCompany: async (companyRequest: CompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyRequest' is not null or undefined
            assertParamExists('saveCompany', 'companyRequest', companyRequest)
            const localVarPath = `/api/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyRequest} companyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (id: number, companyRequest: CompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompany', 'id', id)
            // verify required parameter 'companyRequest' is not null or undefined
            assertParamExists('updateCompany', 'companyRequest', companyRequest)
            const localVarPath = `/api/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyControllerApi - functional programming interface
 * @export
 */
export const CompanyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompany(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompany(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyControllerApi.deleteCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanies(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyControllerApi.getAllCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyControllerApi.getCompanyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CompanyRequest} companyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCompany(companyRequest: CompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCompany(companyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyControllerApi.saveCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyRequest} companyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(id: number, companyRequest: CompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(id, companyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyControllerApi.updateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyControllerApi - factory interface
 * @export
 */
export const CompanyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: number, options?: RawAxiosRequestConfig): AxiosPromise<CompanyResponse> {
            return localVarFp.deleteCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies(options?: RawAxiosRequestConfig): AxiosPromise<Array<CompanyResponse>> {
            return localVarFp.getAllCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<CompanyResponse> {
            return localVarFp.getCompanyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyRequest} companyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCompany(companyRequest: CompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CompanyResponse> {
            return localVarFp.saveCompany(companyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyRequest} companyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(id: number, companyRequest: CompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CompanyResponse> {
            return localVarFp.updateCompany(id, companyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyControllerApi - object-oriented interface
 * @export
 * @class CompanyControllerApi
 * @extends {BaseAPI}
 */
export class CompanyControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public deleteCompany(id: number, options?: RawAxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).deleteCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getAllCompanies(options?: RawAxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getAllCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyById(id: number, options?: RawAxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyRequest} companyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public saveCompany(companyRequest: CompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).saveCompany(companyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CompanyRequest} companyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public updateCompany(id: number, companyRequest: CompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).updateCompany(id, companyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CountryControllerApi - axios parameter creator
 * @export
 */
export const CountryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCountry', 'id', id)
            const localVarPath = `/api/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCountries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCountryById', 'id', id)
            const localVarPath = `/api/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CountryRequest} countryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCountry: async (countryRequest: CountryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryRequest' is not null or undefined
            assertParamExists('saveCountry', 'countryRequest', countryRequest)
            const localVarPath = `/api/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CountryRequest} countryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry: async (id: number, countryRequest: CountryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCountry', 'id', id)
            // verify required parameter 'countryRequest' is not null or undefined
            assertParamExists('updateCountry', 'countryRequest', countryRequest)
            const localVarPath = `/api/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryControllerApi - functional programming interface
 * @export
 */
export const CountryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCountry(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCountry(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerApi.deleteCountry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCountries(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCountries(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerApi.getAllCountries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerApi.getCountryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CountryRequest} countryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCountry(countryRequest: CountryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCountry(countryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerApi.saveCountry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {CountryRequest} countryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCountry(id: number, countryRequest: CountryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCountry(id, countryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryControllerApi.updateCountry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CountryControllerApi - factory interface
 * @export
 */
export const CountryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry(id: number, options?: RawAxiosRequestConfig): AxiosPromise<CountryResponse> {
            return localVarFp.deleteCountry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCountries(options?: RawAxiosRequestConfig): AxiosPromise<Array<CountryResponse>> {
            return localVarFp.getAllCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<CountryResponse> {
            return localVarFp.getCountryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CountryRequest} countryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCountry(countryRequest: CountryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CountryResponse> {
            return localVarFp.saveCountry(countryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CountryRequest} countryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry(id: number, countryRequest: CountryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CountryResponse> {
            return localVarFp.updateCountry(id, countryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryControllerApi - object-oriented interface
 * @export
 * @class CountryControllerApi
 * @extends {BaseAPI}
 */
export class CountryControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public deleteCountry(id: number, options?: RawAxiosRequestConfig) {
        return CountryControllerApiFp(this.configuration).deleteCountry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public getAllCountries(options?: RawAxiosRequestConfig) {
        return CountryControllerApiFp(this.configuration).getAllCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public getCountryById(id: number, options?: RawAxiosRequestConfig) {
        return CountryControllerApiFp(this.configuration).getCountryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CountryRequest} countryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public saveCountry(countryRequest: CountryRequest, options?: RawAxiosRequestConfig) {
        return CountryControllerApiFp(this.configuration).saveCountry(countryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CountryRequest} countryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public updateCountry(id: number, countryRequest: CountryRequest, options?: RawAxiosRequestConfig) {
        return CountryControllerApiFp(this.configuration).updateCountry(id, countryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeControllerApi - axios parameter creator
 * @export
 */
export const EmployeeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} workstationId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEmployeeToWorkstation: async (workstationId: string, employeeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workstationId' is not null or undefined
            assertParamExists('assignEmployeeToWorkstation', 'workstationId', workstationId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('assignEmployeeToWorkstation', 'employeeId', employeeId)
            const localVarPath = `/api/employees/{employeeId}/assign-workstation/{workstationId}`
                .replace(`{${"workstationId"}}`, encodeURIComponent(String(workstationId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmployee', 'id', id)
            const localVarPath = `/api/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableEmployee: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('enableEmployee', 'username', username)
            const localVarPath = `/api/employees/enable/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAssignedEmployees: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/employees/assigned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmployeeById', 'id', id)
            const localVarPath = `/api/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/employees/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEmployee: async (employeeRequest: EmployeeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeRequest' is not null or undefined
            assertParamExists('saveEmployee', 'employeeRequest', employeeRequest)
            const localVarPath = `/api/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEmployee1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/employees/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSetPasswordEmails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/employees/send-set-password-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workstationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignEmployeeToWorkstation: async (workstationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workstationId' is not null or undefined
            assertParamExists('unassignEmployeeToWorkstation', 'workstationId', workstationId)
            const localVarPath = `/api/employees/unassign-workstation/{workstationId}`
                .replace(`{${"workstationId"}}`, encodeURIComponent(String(workstationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EmployeeUpdateRequest} employeeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (id: number, employeeUpdateRequest: EmployeeUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmployee', 'id', id)
            // verify required parameter 'employeeUpdateRequest' is not null or undefined
            assertParamExists('updateEmployee', 'employeeUpdateRequest', employeeUpdateRequest)
            const localVarPath = `/api/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeControllerApi - functional programming interface
 * @export
 */
export const EmployeeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} workstationId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignEmployeeToWorkstation(workstationId: string, employeeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignEmployeeToWorkstation(workstationId, employeeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.assignEmployeeToWorkstation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployee(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployee(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.deleteEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableEmployee(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableEmployee(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.enableEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAssignedEmployees(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAssignedEmployees(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.getAllAssignedEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmployees(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEmployees(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.getAllEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.getEmployeeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.getRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEmployee(employeeRequest: EmployeeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEmployee(employeeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.saveEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEmployee1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEmployee1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.saveEmployee1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSetPasswordEmails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSetPasswordEmails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.sendSetPasswordEmails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} workstationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignEmployeeToWorkstation(workstationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignEmployeeToWorkstation(workstationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.unassignEmployeeToWorkstation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {EmployeeUpdateRequest} employeeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(id: number, employeeUpdateRequest: EmployeeUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(id, employeeUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeControllerApi.updateEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeControllerApi - factory interface
 * @export
 */
export const EmployeeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} workstationId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEmployeeToWorkstation(workstationId: string, employeeId: number, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.assignEmployeeToWorkstation(workstationId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee(id: number, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.deleteEmployee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableEmployee(username: string, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.enableEmployee(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAssignedEmployees(options?: RawAxiosRequestConfig): AxiosPromise<Array<EmployeeResponse>> {
            return localVarFp.getAllAssignedEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees(options?: RawAxiosRequestConfig): AxiosPromise<Array<EmployeeResponse>> {
            return localVarFp.getAllEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.getEmployeeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmployeeRequest} employeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEmployee(employeeRequest: EmployeeRequest, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.saveEmployee(employeeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEmployee1(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.saveEmployee1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSetPasswordEmails(options?: RawAxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.sendSetPasswordEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workstationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignEmployeeToWorkstation(workstationId: string, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.unassignEmployeeToWorkstation(workstationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EmployeeUpdateRequest} employeeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(id: number, employeeUpdateRequest: EmployeeUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.updateEmployee(id, employeeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeControllerApi - object-oriented interface
 * @export
 * @class EmployeeControllerApi
 * @extends {BaseAPI}
 */
export class EmployeeControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} workstationId 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public assignEmployeeToWorkstation(workstationId: string, employeeId: number, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).assignEmployeeToWorkstation(workstationId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public deleteEmployee(id: number, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).deleteEmployee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public enableEmployee(username: string, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).enableEmployee(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getAllAssignedEmployees(options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getAllAssignedEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getAllEmployees(options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getAllEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getEmployeeById(id: number, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getEmployeeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public getRoles(options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmployeeRequest} employeeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public saveEmployee(employeeRequest: EmployeeRequest, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).saveEmployee(employeeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public saveEmployee1(options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).saveEmployee1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public sendSetPasswordEmails(options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).sendSetPasswordEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workstationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public unassignEmployeeToWorkstation(workstationId: string, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).unassignEmployeeToWorkstation(workstationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EmployeeUpdateRequest} employeeUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeControllerApi
     */
    public updateEmployee(id: number, employeeUpdateRequest: EmployeeUpdateRequest, options?: RawAxiosRequestConfig) {
        return EmployeeControllerApiFp(this.configuration).updateEmployee(id, employeeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FloorControllerApi - axios parameter creator
 * @export
 */
export const FloorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFloor: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFloor', 'id', id)
            const localVarPath = `/api/floors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFloors: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/floors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFloorById', 'id', id)
            const localVarPath = `/api/floors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSVGById: async (fileName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('getSVGById', 'fileName', fileName)
            const localVarPath = `/api/floors/downloadFile/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [nameForAdmins] 
         * @param {string} [nameForUsers] 
         * @param {number} [level] 
         * @param {File} [svg] 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFloor: async (id?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/floors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (nameForAdmins !== undefined) { 
                localVarFormParams.append('nameForAdmins', nameForAdmins as any);
            }
    
            if (nameForUsers !== undefined) { 
                localVarFormParams.append('nameForUsers', nameForUsers as any);
            }
    
            if (level !== undefined) { 
                localVarFormParams.append('level', level as any);
            }
    
            if (svg !== undefined) { 
                localVarFormParams.append('svg', svg as any);
            }
    
            if (buildingId !== undefined) { 
                localVarFormParams.append('buildingId', buildingId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [id2] 
         * @param {string} [nameForAdmins] 
         * @param {string} [nameForUsers] 
         * @param {number} [level] 
         * @param {File} [svg] 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFloor: async (id: number, id2?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFloor', 'id', id)
            const localVarPath = `/api/floors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (id2 !== undefined) { 
                localVarFormParams.append('id', id2 as any);
            }
    
            if (nameForAdmins !== undefined) { 
                localVarFormParams.append('nameForAdmins', nameForAdmins as any);
            }
    
            if (nameForUsers !== undefined) { 
                localVarFormParams.append('nameForUsers', nameForUsers as any);
            }
    
            if (level !== undefined) { 
                localVarFormParams.append('level', level as any);
            }
    
            if (svg !== undefined) { 
                localVarFormParams.append('svg', svg as any);
            }
    
            if (buildingId !== undefined) { 
                localVarFormParams.append('buildingId', buildingId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<WorkstationRequest>} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkstationsInFloor: async (id: number, workstationRequest: Array<WorkstationRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkstationsInFloor', 'id', id)
            // verify required parameter 'workstationRequest' is not null or undefined
            assertParamExists('updateWorkstationsInFloor', 'workstationRequest', workstationRequest)
            const localVarPath = `/api/floors/{id}/workstations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workstationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FloorControllerApi - functional programming interface
 * @export
 */
export const FloorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FloorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFloor(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorWithBuildingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFloor(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FloorControllerApi.deleteFloor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFloors(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FloorWithBuildingInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFloors(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FloorControllerApi.getAllFloors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFloorById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorWithBuildingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFloorById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FloorControllerApi.getFloorById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSVGById(fileName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSVGById(fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FloorControllerApi.getSVGById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [nameForAdmins] 
         * @param {string} [nameForUsers] 
         * @param {number} [level] 
         * @param {File} [svg] 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFloor(id?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorWithBuildingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFloor(id, nameForAdmins, nameForUsers, level, svg, buildingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FloorControllerApi.saveFloor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [id2] 
         * @param {string} [nameForAdmins] 
         * @param {string} [nameForUsers] 
         * @param {number} [level] 
         * @param {File} [svg] 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFloor(id: number, id2?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorWithBuildingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFloor(id, id2, nameForAdmins, nameForUsers, level, svg, buildingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FloorControllerApi.updateFloor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<WorkstationRequest>} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkstationsInFloor(id: number, workstationRequest: Array<WorkstationRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkstationsInFloor(id, workstationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FloorControllerApi.updateWorkstationsInFloor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FloorControllerApi - factory interface
 * @export
 */
export const FloorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FloorControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFloor(id: number, options?: RawAxiosRequestConfig): AxiosPromise<FloorWithBuildingInfoResponse> {
            return localVarFp.deleteFloor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFloors(options?: RawAxiosRequestConfig): AxiosPromise<Array<FloorWithBuildingInfoResponse>> {
            return localVarFp.getAllFloors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<FloorWithBuildingInfoResponse> {
            return localVarFp.getFloorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSVGById(fileName: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getSVGById(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [nameForAdmins] 
         * @param {string} [nameForUsers] 
         * @param {number} [level] 
         * @param {File} [svg] 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFloor(id?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options?: RawAxiosRequestConfig): AxiosPromise<FloorWithBuildingInfoResponse> {
            return localVarFp.saveFloor(id, nameForAdmins, nameForUsers, level, svg, buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [id2] 
         * @param {string} [nameForAdmins] 
         * @param {string} [nameForUsers] 
         * @param {number} [level] 
         * @param {File} [svg] 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFloor(id: number, id2?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options?: RawAxiosRequestConfig): AxiosPromise<FloorWithBuildingInfoResponse> {
            return localVarFp.updateFloor(id, id2, nameForAdmins, nameForUsers, level, svg, buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<WorkstationRequest>} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkstationsInFloor(id: number, workstationRequest: Array<WorkstationRequest>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateWorkstationsInFloor(id, workstationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FloorControllerApi - object-oriented interface
 * @export
 * @class FloorControllerApi
 * @extends {BaseAPI}
 */
export class FloorControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorControllerApi
     */
    public deleteFloor(id: number, options?: RawAxiosRequestConfig) {
        return FloorControllerApiFp(this.configuration).deleteFloor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorControllerApi
     */
    public getAllFloors(options?: RawAxiosRequestConfig) {
        return FloorControllerApiFp(this.configuration).getAllFloors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorControllerApi
     */
    public getFloorById(id: number, options?: RawAxiosRequestConfig) {
        return FloorControllerApiFp(this.configuration).getFloorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorControllerApi
     */
    public getSVGById(fileName: string, options?: RawAxiosRequestConfig) {
        return FloorControllerApiFp(this.configuration).getSVGById(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [nameForAdmins] 
     * @param {string} [nameForUsers] 
     * @param {number} [level] 
     * @param {File} [svg] 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorControllerApi
     */
    public saveFloor(id?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options?: RawAxiosRequestConfig) {
        return FloorControllerApiFp(this.configuration).saveFloor(id, nameForAdmins, nameForUsers, level, svg, buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [id2] 
     * @param {string} [nameForAdmins] 
     * @param {string} [nameForUsers] 
     * @param {number} [level] 
     * @param {File} [svg] 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorControllerApi
     */
    public updateFloor(id: number, id2?: number, nameForAdmins?: string, nameForUsers?: string, level?: number, svg?: File, buildingId?: number, options?: RawAxiosRequestConfig) {
        return FloorControllerApiFp(this.configuration).updateFloor(id, id2, nameForAdmins, nameForUsers, level, svg, buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<WorkstationRequest>} workstationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorControllerApi
     */
    public updateWorkstationsInFloor(id: number, workstationRequest: Array<WorkstationRequest>, options?: RawAxiosRequestConfig) {
        return FloorControllerApiFp(this.configuration).updateWorkstationsInFloor(id, workstationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportsControllerApi - axios parameter creator
 * @export
 */
export const ReportsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [employeeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicReports: async (from?: string, to?: string, employeeName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/basic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substring(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substring(0,10) :
                    to;
            }

            if (employeeName !== undefined) {
                localVarQueryParameter['employeeName'] = employeeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyReports: async (from?: string, to?: string, employeeId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/daily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substring(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substring(0,10) :
                    to;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStillInsideReports: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/inside`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/token-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekReports: async (date?: string, employeeId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/week`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsControllerApi - functional programming interface
 * @export
 */
export const ReportsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [employeeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBasicReports(from?: string, to?: string, employeeName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeInSpaceBasicReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicReports(from, to, employeeName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsControllerApi.getBasicReports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyReports(from?: string, to?: string, employeeId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeInSpaceWeekDayReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyReports(from, to, employeeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsControllerApi.getDailyReports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStillInsideReports(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeStillInsideResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStillInsideReports(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsControllerApi.getStillInsideReports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsControllerApi.getTokenInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeekReports(date?: string, employeeId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeInSpaceWeekReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeekReports(date, employeeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsControllerApi.getWeekReports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportsControllerApi - factory interface
 * @export
 */
export const ReportsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [employeeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicReports(from?: string, to?: string, employeeName?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TimeInSpaceBasicReportResponse>> {
            return localVarFp.getBasicReports(from, to, employeeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyReports(from?: string, to?: string, employeeId?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TimeInSpaceWeekDayReportResponse>> {
            return localVarFp.getDailyReports(from, to, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStillInsideReports(options?: RawAxiosRequestConfig): AxiosPromise<Array<EmployeeStillInsideResponse>> {
            return localVarFp.getStillInsideReports(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenInfo(options?: RawAxiosRequestConfig): AxiosPromise<TokenInfoResponse> {
            return localVarFp.getTokenInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekReports(date?: string, employeeId?: number, options?: RawAxiosRequestConfig): AxiosPromise<TimeInSpaceWeekReportResponse> {
            return localVarFp.getWeekReports(date, employeeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsControllerApi - object-oriented interface
 * @export
 * @class ReportsControllerApi
 * @extends {BaseAPI}
 */
export class ReportsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [employeeName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsControllerApi
     */
    public getBasicReports(from?: string, to?: string, employeeName?: string, options?: RawAxiosRequestConfig) {
        return ReportsControllerApiFp(this.configuration).getBasicReports(from, to, employeeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [employeeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsControllerApi
     */
    public getDailyReports(from?: string, to?: string, employeeId?: number, options?: RawAxiosRequestConfig) {
        return ReportsControllerApiFp(this.configuration).getDailyReports(from, to, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsControllerApi
     */
    public getStillInsideReports(options?: RawAxiosRequestConfig) {
        return ReportsControllerApiFp(this.configuration).getStillInsideReports(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsControllerApi
     */
    public getTokenInfo(options?: RawAxiosRequestConfig) {
        return ReportsControllerApiFp(this.configuration).getTokenInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {number} [employeeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsControllerApi
     */
    public getWeekReports(date?: string, employeeId?: number, options?: RawAxiosRequestConfig) {
        return ReportsControllerApiFp(this.configuration).getWeekReports(date, employeeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoomControllerApi - axios parameter creator
 * @export
 */
export const RoomControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoom', 'id', id)
            const localVarPath = `/api/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRooms: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRoomById', 'id', id)
            const localVarPath = `/api/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OfficeRoomRequest} officeRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (officeRoomRequest: OfficeRoomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeRoomRequest' is not null or undefined
            assertParamExists('save', 'officeRoomRequest', officeRoomRequest)
            const localVarPath = `/api/rooms/office`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officeRoomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConferenceRoomRequest} conferenceRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1: async (conferenceRoomRequest: ConferenceRoomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceRoomRequest' is not null or undefined
            assertParamExists('save1', 'conferenceRoomRequest', conferenceRoomRequest)
            const localVarPath = `/api/rooms/conference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceRoomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OfficeRoomRequest} officeRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, officeRoomRequest: OfficeRoomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'officeRoomRequest' is not null or undefined
            assertParamExists('update', 'officeRoomRequest', officeRoomRequest)
            const localVarPath = `/api/rooms/{id}/office`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officeRoomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ConferenceRoomRequest} conferenceRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (id: number, conferenceRoomRequest: ConferenceRoomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update1', 'id', id)
            // verify required parameter 'conferenceRoomRequest' is not null or undefined
            assertParamExists('update1', 'conferenceRoomRequest', conferenceRoomRequest)
            const localVarPath = `/api/rooms/{id}/conference`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceRoomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomControllerApi - functional programming interface
 * @export
 */
export const RoomControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoom(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoom(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomControllerApi.deleteRoom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRooms(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRooms(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomControllerApi.getAllRooms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoomById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomControllerApi.getRoomById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {OfficeRoomRequest} officeRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(officeRoomRequest: OfficeRoomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(officeRoomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomControllerApi.save']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ConferenceRoomRequest} conferenceRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save1(conferenceRoomRequest: ConferenceRoomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save1(conferenceRoomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomControllerApi.save1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {OfficeRoomRequest} officeRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, officeRoomRequest: OfficeRoomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, officeRoomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomControllerApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {ConferenceRoomRequest} conferenceRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(id: number, conferenceRoomRequest: ConferenceRoomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(id, conferenceRoomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomControllerApi.update1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RoomControllerApi - factory interface
 * @export
 */
export const RoomControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom(id: number, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.deleteRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRooms(options?: RawAxiosRequestConfig): AxiosPromise<Array<RoomResponse>> {
            return localVarFp.getAllRooms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomById(id: number, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.getRoomById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OfficeRoomRequest} officeRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(officeRoomRequest: OfficeRoomRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.save(officeRoomRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConferenceRoomRequest} conferenceRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save1(conferenceRoomRequest: ConferenceRoomRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.save1(conferenceRoomRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OfficeRoomRequest} officeRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, officeRoomRequest: OfficeRoomRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.update(id, officeRoomRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ConferenceRoomRequest} conferenceRoomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id: number, conferenceRoomRequest: ConferenceRoomRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.update1(id, conferenceRoomRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomControllerApi - object-oriented interface
 * @export
 * @class RoomControllerApi
 * @extends {BaseAPI}
 */
export class RoomControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public deleteRoom(id: number, options?: RawAxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).deleteRoom(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public getAllRooms(options?: RawAxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).getAllRooms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public getRoomById(id: number, options?: RawAxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).getRoomById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfficeRoomRequest} officeRoomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public save(officeRoomRequest: OfficeRoomRequest, options?: RawAxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).save(officeRoomRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConferenceRoomRequest} conferenceRoomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public save1(conferenceRoomRequest: ConferenceRoomRequest, options?: RawAxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).save1(conferenceRoomRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OfficeRoomRequest} officeRoomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public update(id: number, officeRoomRequest: OfficeRoomRequest, options?: RawAxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).update(id, officeRoomRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ConferenceRoomRequest} conferenceRoomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomControllerApi
     */
    public update1(id: number, conferenceRoomRequest: ConferenceRoomRequest, options?: RawAxiosRequestConfig) {
        return RoomControllerApiFp(this.configuration).update1(id, conferenceRoomRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatisticsControllerApi - axios parameter creator
 * @export
 */
export const StatisticsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingsStatistic: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesStatistic: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistic: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsControllerApi - functional programming interface
 * @export
 */
export const StatisticsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuildingsStatistic(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingStatistic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuildingsStatistic(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatisticsControllerApi.getBuildingsStatistic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesStatistic(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyStatistic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesStatistic(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatisticsControllerApi.getCompaniesStatistic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralStatistic(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralStatistic(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatisticsControllerApi.getGeneralStatistic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StatisticsControllerApi - factory interface
 * @export
 */
export const StatisticsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingsStatistic(options?: RawAxiosRequestConfig): AxiosPromise<Array<BuildingStatistic>> {
            return localVarFp.getBuildingsStatistic(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesStatistic(options?: RawAxiosRequestConfig): AxiosPromise<Array<CompanyStatistic>> {
            return localVarFp.getCompaniesStatistic(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistic(options?: RawAxiosRequestConfig): AxiosPromise<GeneralStatistic> {
            return localVarFp.getGeneralStatistic(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsControllerApi - object-oriented interface
 * @export
 * @class StatisticsControllerApi
 * @extends {BaseAPI}
 */
export class StatisticsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getBuildingsStatistic(options?: RawAxiosRequestConfig) {
        return StatisticsControllerApiFp(this.configuration).getBuildingsStatistic(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getCompaniesStatistic(options?: RawAxiosRequestConfig) {
        return StatisticsControllerApiFp(this.configuration).getCompaniesStatistic(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getGeneralStatistic(options?: RawAxiosRequestConfig) {
        return StatisticsControllerApiFp(this.configuration).getGeneralStatistic(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkstationControllerApi - axios parameter creator
 * @export
 */
export const WorkstationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkstation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkstation', 'id', id)
            const localVarPath = `/api/workstations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkstations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workstations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkstationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkstationById', 'id', id)
            const localVarPath = `/api/workstations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkstationRequest} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveWorkstation: async (workstationRequest: WorkstationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workstationRequest' is not null or undefined
            assertParamExists('saveWorkstation', 'workstationRequest', workstationRequest)
            const localVarPath = `/api/workstations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workstationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkstationRequest} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkstation: async (id: string, workstationRequest: WorkstationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkstation', 'id', id)
            // verify required parameter 'workstationRequest' is not null or undefined
            assertParamExists('updateWorkstation', 'workstationRequest', workstationRequest)
            const localVarPath = `/api/workstations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workstationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkstationControllerApi - functional programming interface
 * @export
 */
export const WorkstationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkstationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkstation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkstation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkstationControllerApi.deleteWorkstation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkstations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkstationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkstations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkstationControllerApi.getAllWorkstations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkstationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkstationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkstationControllerApi.getWorkstationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkstationRequest} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveWorkstation(workstationRequest: WorkstationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveWorkstation(workstationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkstationControllerApi.saveWorkstation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkstationRequest} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkstation(id: string, workstationRequest: WorkstationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkstation(id, workstationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkstationControllerApi.updateWorkstation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkstationControllerApi - factory interface
 * @export
 */
export const WorkstationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkstationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkstation(id: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkstationResponse> {
            return localVarFp.deleteWorkstation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkstations(options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkstationResponse>> {
            return localVarFp.getAllWorkstations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkstationById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkstationResponse> {
            return localVarFp.getWorkstationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkstationRequest} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveWorkstation(workstationRequest: WorkstationRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkstationResponse> {
            return localVarFp.saveWorkstation(workstationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WorkstationRequest} workstationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkstation(id: string, workstationRequest: WorkstationRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkstationResponse> {
            return localVarFp.updateWorkstation(id, workstationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkstationControllerApi - object-oriented interface
 * @export
 * @class WorkstationControllerApi
 * @extends {BaseAPI}
 */
export class WorkstationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkstationControllerApi
     */
    public deleteWorkstation(id: string, options?: RawAxiosRequestConfig) {
        return WorkstationControllerApiFp(this.configuration).deleteWorkstation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkstationControllerApi
     */
    public getAllWorkstations(options?: RawAxiosRequestConfig) {
        return WorkstationControllerApiFp(this.configuration).getAllWorkstations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkstationControllerApi
     */
    public getWorkstationById(id: string, options?: RawAxiosRequestConfig) {
        return WorkstationControllerApiFp(this.configuration).getWorkstationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkstationRequest} workstationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkstationControllerApi
     */
    public saveWorkstation(workstationRequest: WorkstationRequest, options?: RawAxiosRequestConfig) {
        return WorkstationControllerApiFp(this.configuration).saveWorkstation(workstationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {WorkstationRequest} workstationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkstationControllerApi
     */
    public updateWorkstation(id: string, workstationRequest: WorkstationRequest, options?: RawAxiosRequestConfig) {
        return WorkstationControllerApiFp(this.configuration).updateWorkstation(id, workstationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



